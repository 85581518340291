import { Promise } from 'the-promise'
import { CLIENT_LIST } from './data/clientList'

export class BackendMockClient 
{
    constructor()
    {
        console.debug("[BackendMockClient] Constructor")
    }

    getWorldUsers()
    {
        return this._reply(CLIENT_LIST);
    }

    _reply(data)
    {
        return Promise.timeout(500)
            .then(() => data);
    }
}