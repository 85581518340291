const CLIENT_LIST = {
    clients: [
        {
            location: {
                lat: 37.6422856,
                long: -122.4812588,
            },
            product: 'Kubevious',
            version: 'v1.2.3',
            counters: [
                {
                    name: 'node',
                    count: 25,
                },
                // {
                //     name: 'pod',
                //     count: 2,
                // },
            ],
        },
        {
            location: {
                lat: 21.505,
                long: -5.09,
            },
            product: 'Kubevious',
            version: 'v1.2.1',
            counters: [
                {
                    name: 'node',
                    count: 150,
                },
                {
                    name: 'pod',
                    count: 98,
                },
            ],
        },
        {
            location: {
                lat: 68.505,
                long: 30.29,
            },
            product: 'Kubevious',
            version: 'v1.2.3',
            counters: [
                {
                    name: 'node',
                    count: 32,
                },
                {
                    name: 'pod',
                    count: 6,
                },
            ],
        },
        {
            location: {
                lat: 51.505,
                long: -0.09,
            },
            product: 'Kubevious',
            version: 'v1.2.3',
            counters: [
                {
                    name: 'node',
                    count: 37,
                },
                {
                    name: 'pod',
                    count: 24,
                },
            ],
        },
        {
            location: {
                lat: 41.505,
                long: -90.09,
            },
            product: 'Kubevious SAAS',
            version: 'v1.1.3',
            counters: [
                {
                    name: 'node',
                    count: 120,
                },
                {
                    name: 'pod',
                    count: 80,
                },
            ],
        },
        {
            location: {
                lat: 51.505,
                long: -0.09,
            },
            product: 'Kubevious',
            version: 'v1.2.1',
            counters: [
                {
                    name: 'node',
                    count: 72,
                },
                {
                    name: 'pod',
                    count: 42,
                },
            ],
        },
        {
            location: {
                lat: 51.505,
                long: -1.2,
            },
            product: 'Kubevious',
            version: 'v1.2.2',
            counters: [
                // {
                //     name: 'node',
                //     count: 14,
                // },
                {
                    name: 'pod',
                    count: 5,
                },
            ],
        },
    ],
};

module.exports.CLIENT_LIST = CLIENT_LIST
