import { Promise } from 'the-promise'
import { BackendClient } from './backend-client'

export class BackendClientImpl
{
    constructor()
    {
        console.debug("[BackendClientImpl] Constructor")

        this._client = new BackendClient();
    }

    getWorldUsers()
    {
        return this._client.get('/api/v1/worldvious/users')
            .then(result => {
                return result.data;
            })
    }
}