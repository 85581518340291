import axios from 'axios'

export class BackendClient {
    constructor()
    {
        this._urlBase = process.env.GATSBY_BACKEND_URL;
        this._headers = {};
    }

    get(url, params) {
        return this._execute('get', url, params, null);
    }

    delete(url, params) {
        return this._execute('delete', url, params, null);
    }

    post(url, data, params) {
        return this._execute('post', url, params, data);
    }

    put(url, data, params) {
        return this._execute('put', url, params, data);
    }

    _execute(method, url, params, data) {
        if (this._urlBase) {
            url = this._urlBase + url;
        }
        
        var options = {
            method: method,
            url: url,
            headers: this._headers
        };

        if (params) {
            options.params = params;
        }

        if (data) {
            options.data = data;
        }

        console.debug("[BackendClient] %s :: %s ...", options.method, options.url)
        return axios(options)
            .then(result => {
                console.debug("[BackendClient] %s :: %s DONE.", options.method, options.url)
                return result;
            })
            .catch(reason => {
                console.error("[BackendClient] %s :: %s ERROR: ", options.method, options.url, reason)
                let data = null;
                let status = null;
                if (reason.response) {
                    data = reason.response.data;
                    status = reason.response.status;
                } else {
                    data = reason.message;
                    status = 0;
                }
                console.error("[BackendClient] ERROR DATA: ", data)
                console.error("[BackendClient] ERROR STATUS: ", status)

                throw reason;
            });
    }
}