import _ from 'the-lodash'
import './src/styles/global.css'
import { sharedState, backendClient } from './src/utils/configure-service';

const pages = {
    '/world': {
        onload: () => {
            backendClient.getWorldUsers((data) => {
                sharedState.set('worldvious_users', data);
            })
            .catch(reason => {
                
            });
        }
    }
}

function getPageMeta(url)
{
    if (_.endsWith(url, '/')) {
        url = url.slice(0, -1)
    }
    const pageMeta = pages[url];
    if (pageMeta) {
        return pageMeta;
    }
    return null;
}

function prepareBackend(url)
{
    const pageMeta = getPageMeta(url);
    if (pageMeta) {
        if (pageMeta.onload)
        {
            pageMeta.onload();
        }
    }
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
    prepareBackend(location.pathname);
}

export const shouldUpdateScroll = () => {
    document.getElementById('___gatsby').scrollTo(0, 0)

    return false
}
