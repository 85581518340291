import { Promise } from 'the-promise'
import { BackendMockClient } from './mock/mock-impl'
import { BackendClientImpl } from './backend-impl'

export class BackendClient 
{
    constructor()
    {
        console.debug("[BackendClient] Constructor")
        if (process.env.GATSBY_BACKEND_MOCK) {
            this._impl = new BackendMockClient();
        } else {
            this._impl = new BackendClientImpl();
        }
    }

    getWorldUsers(cb)
    {
        return Promise.resolve(this._impl.getWorldUsers())
            .then(data => {
                cb(data);
            })
            ;
    }
}